import React, { useState, useEffect } from "react";
import "./login.scss";
import { Link, useHistory } from "react-router-dom";
import { API_URL } from "../../utils/ApiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from '../loader/Loader';
import { Modal } from "react-bootstrap";

const Login = () => {

  const history = useHistory();

  const [loader, setLoader] = useState(false);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setError] = useState("");
  const [errors, setErrors] = useState({});




  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [errorF, setErrorF] = useState(null);




  const payload = {
    email: email,
    password: password
  }

  const loginHandle = async () => {

    if (!password && !email) {
      setErrorF("Please enter email and paasword");
      handleShow3();
      return
    }

    if (password && !email) {
      setErrorF("Please enter email");
      handleShow3();
      return
    }

    if (!password && email) {
      setErrorF("Please enter paasword");
      handleShow3();
      return
    }

    if (password?.length < 6) {
      setErrorF("Paasword must more then 6 letters");
      handleShow3();
      return
    }

    setLoader(true);

    await axios.post(`${API_URL}/v1/auth/login`, payload)
      .then((res) => {
        console.log(res, 'res in signup');
        if (res) {
          console.log("🚀 ~ file: Login.js:75 ~ .then ~ res:", res)
          localStorage?.setItem('userId', res?.data?.user?.id);
          localStorage?.setItem('accessToken', res?.data?.token?.accessToken);
          setLoader(false);
          // setErrorF("Login successfully")
          handleShow2();
          setEmail("");
          setPassword("");
          // history.push('/dashboard');
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: Login.js:86 ~ loginHandle ~ err:", err?.response?.data?.message)
        setErrorF(err?.response?.data?.message)
        handleShow3();
        setLoader(false);
      })
  }

  return (
    <>
      {loader && <Loader />}

      <section className="main-login">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-sm-12">
              <div className="maincard">
                <div className="logincard">
                  <div className="innerlogo">
                    <img src="\logo.svg" alt="img" className="img-fluid" />
                  </div>
                  <div className="cardtext">
                    <h6>log in to your admin account</h6>
                    <p>
                      Please enter your credentials below to log into your account.
                    </p>
                    <input
                      type="text"
                      placeholder="Username or Email"
                      value={email}
                      onChange={(e) => setEmail(e?.target?.value)}
                    />
                    <div
                      style={{ position: "unset" }}
                      className="text-danger mb-4"
                    >
                      <small>{errors.username}</small>
                    </div>
                    <div className="parent">
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e?.target?.value)}
                      />
                      <div
                        style={{ position: "unset" }}
                        className="text-danger mb-4"
                      >
                        <small>{errors.password}</small>
                      </div>
                      <img
                        src="\assets\eye.svg"
                        className="img-fluid"
                        alt="img"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                  </div>
                  <div className="chec mb-4">
                    <label class="material-checkbox">
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                      Keep me logged in
                    </label>
                  </div>
                  {/* <Link to="dashboard"> */}
                  <div className="mt-5">
                    <div
                      style={{ position: "unset" }}
                      className="text-danger mb-2"
                    >
                      <small>{showError}</small>
                    </div>
                    <div className="endbtn">
                      {/* <Link to="/dashboard"> */}
                      <button onClick={loginHandle}>Log In</button>
                      {/* </Link> */}
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Modal className='profilemodal' show={show2} onHide={handleClose2} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className='invest_modalbody'>
          <div className='parenttick'>
            <div className='images'>
              <img src='\assets\successtick.svg' alt='img' className='img-fluid' />
            </div>
            <div className='text'>
              <p>Login successfully</p>
            </div>
            <div className='okaybtn' onClick={() => history.push('/dashboard')}>
              <button>Okay</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className='profilemodal' show={show3} onHide={handleClose3} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className='invest_modalbody'>
          <div className='parenttick'>
            <div className='images'>
              <img src='\assets\cross.svg' alt='img' className='img-fluid' />
            </div>
            <div className='text'>
              <p>{errorF}</p>
            </div>
            <div className='okaybtn' onClick={handleClose3}>
              <button>Okay</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Login;
